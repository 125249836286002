import * as React from "react";
import ReactPaginate from 'react-paginate';
import { useState, useEffect } from 'react';
import TopicCard from "../components/topicCard";
import '../App.css';
import { useAppContext } from '../AppContext';
import { SearchFilterComponent, SortDropdown } from "../components/searchSortTopics";
import TopicAccessModal from "../components/topicAccessModal";
import { CloseButton } from "../components/closeButton";
import CreateTopicModal from "../components/topicCreateModal";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const HomePage = () => {
    const { communityConfig } = useAppContext();
    const navigate = useNavigate();
    const [isTopicAccessModalOpen, setTopicAccessModalOpen] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [isAskQuestionModalOpen, setAskQuestionModalOpen] = useState(false);
    const [topics, setTopics] = useState("loading");
    const [searchTerm, setSearchTerm] = useState(() => {
        const storedSearchTerm = sessionStorage.getItem('searchTerm');
        return storedSearchTerm ? storedSearchTerm : '';
    });
    const [pageNum, setPageNum] = useState(parseInt(sessionStorage.getItem('currentPage') || '1'));
    const [pageNumTotal, setPageNumTotal] = useState(10);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedCommunities, setSelectedCommunities] = useState(() => JSON.parse(sessionStorage.getItem('selectedCommunities')) || {});
    const [sortCriteria, setSortCriteria] = useState(() => sessionStorage.getItem('sortCriteria') || 'recent');
    const jwt = localStorage.getItem("token");

    const apiRoot = process.env.REACT_APP_API_URL;

    const openAskQuestionModal = () => setAskQuestionModalOpen(true);
    const closeAskQuestionModal = () => setAskQuestionModalOpen(false);

    const openTopicAccessModal = (topic) => {
        setTopicAccessModalOpen(true);
        setSelectedTopic(topic);
    };
    const closeTopicAccessModal = () => {
        setTopicAccessModalOpen(false);
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        sessionStorage.setItem('selectedCommunities', JSON.stringify(selectedCommunities));
        sessionStorage.setItem('sortCriteria', sortCriteria);
    }, [selectedCommunities, sortCriteria]);

    const toggleCommunitySelection = (id) => {
        if (Object.keys(communityConfig).length > 1) {
            setSelectedCommunities(prev => ({
                ...prev,
                [id]: !prev[id]
            }));
            setPageNum(1);
            sessionStorage.setItem('currentPage', '1');
        }
    };

    const apiUrl = apiRoot + '/topics';
    const fetchTopics = async (searchTerm = '') => {
        var headerAuth = {}
        var owned = ""
        if (jwt) {
            headerAuth = {
                'Authorization': 'Bearer ' + jwt
            }
            owned = "/owned"
        }

        const communitiesToSend = Object.keys(selectedCommunities).length === 0 && communityConfig
            ? Object.keys(communityConfig).map(id => parseInt(id))
            : Object.entries(selectedCommunities).filter(([id, isSelected]) => isSelected).map(([id]) => parseInt(id));

        try {
            const response = await fetch(apiUrl + owned
                + "?sort=" + sortCriteria
                + "&page=" + pageNum, {
                method: 'POST',
                headers: {
                    ...headerAuth,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    filters: {
                        "communities": communitiesToSend,
                        "owned": false,
                        search: searchTerm
                    }
                })
            });

            if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
                const data = await response.json();
                setPageNumTotal(data.total_pages)
                setTopics(data.topics);
            }

            if (!response.ok) {
                const responseBody = await response.text();
                throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
            }
        } catch (error) {
            toast.error(`Error fetching topics: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.removeItem('searchTerm');
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        fetchTopics(searchTerm);
    }, [jwt, sortCriteria, selectedCommunities, pageNum, communityConfig, searchTerm]);

    const handleSearch = (newSearchTerm) => {
        setSearchTerm(newSearchTerm);
        setPageNum(1);
        sessionStorage.setItem('currentPage', '1');
        sessionStorage.setItem('searchTerm', newSearchTerm);
    };

    const handleSortChange = (newSortCriteria) => {
        setSortCriteria(newSortCriteria);
        setPageNum(1);
        sessionStorage.setItem('currentPage', '1');
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setPageNum(selectedPage);
        sessionStorage.setItem('currentPage', selectedPage.toString());
    };

    useEffect(() => {
        if (isTopicAccessModalOpen || isAskQuestionModalOpen) {
            document.body.style.overflow = 'hidden';
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                if (isTopicAccessModalOpen) {
                    closeTopicAccessModal();
                }
                if (isAskQuestionModalOpen) {
                    closeAskQuestionModal();
                }
            };
        } else {
            document.body.style.overflow = 'auto';
            window.onpopstate = null;
        }
    
        return () => {
            document.body.style.overflow = 'auto';
            window.onpopstate = null;
        };
    }, [isTopicAccessModalOpen, isAskQuestionModalOpen]);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPageRangeDisplayed = () => {
        if (windowWidth < 640) return 2; // for small screens
        if (windowWidth < 768) return 3; // for medium screens
        if (windowWidth < 1024) return 4; // for large screens
        return 5; // for extra large screens
    };

    return (
        <div className="relative select-none flex grow flex-col items-stretch max-md:max-w-full">
            <div className="justify-end items-stretch bg-slate-100 flex flex-col max-md:max-w-full">
                <div className="flex flex-wrap">
                    <SearchFilterComponent 
                        onSearch={handleSearch}
                        onCommunityToggle={toggleCommunitySelection}
                        communityConfig={communityConfig}
                        selectedCommunities={selectedCommunities}
                    />
                    <button className="text-white grow hover:bg-cyan-600 transition-all min-h-20 sm:h-auto text-center text-xl font-bold leading-5 bg-cyan-700" onClick={openAskQuestionModal}>
                        <b>Post</b> a new <b>question</b> or <b>topic</b>
                    </button>
                </div>
                <SortDropdown 
                        sortCriteria={sortCriteria}
                        onSortChange={handleSortChange}
                    />
                {topics === "loading" ? <TopicCard /> : topics === null ? <div className='p-4 text-cyan-950 text-xl font-semibold leading-8 my-3 max-md:max-w-full'>No questions yet!</div> : topics.map((topic) => (
                    (topic.authd === true) ? (
                        <div key={topic.id} className="items-stretch cursor-pointer border-b-[color:var(--DD-Palette-Neutrals-Light,#E6E6E6)]  border-b-2 border-solid bg-slate-50 flex flex-col justify-center px-5 max-md:max-w-full" onClick={() => navigate(`/topic/${topic.id}`)}>
                            <TopicCard highlight={[searchTerm]} topic={topic} />
                        </div>
                    ) : (
                        <div key={topic.id} className="items-stretch bg-gray-200 cursor-pointer border-b-[color:var(--DD-Palette-Neutrals-Light,#E6E6E6)]  border-b-2 border-solid bg-slate-50 flex flex-col justify-center px-5 max-md:max-w-full" onClick={() => openTopicAccessModal(topic)}>
                            <TopicCard highlight={[searchTerm]} topic={topic} />
                        </div>
                    )
                ))}
                <div className="flex select-none justify-center m-8 overflow-x-auto">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowForward className="text-lg" />}
                        forcePage={pageNum - 1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={getPageRangeDisplayed()}
                        pageCount={pageNumTotal}
                        previousLabel={<IoIosArrowBack className="text-lg" />}
                        renderOnZeroPageCount={null}
                        containerClassName="flex items-center justify-center gap-1 sm:gap-2"
                        pageLinkClassName="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full text-cyan-700 hover:bg-slate-400 hover:text-white"
                        previousLinkClassName="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full bg-cyan-600 text-slate-100 hover:bg-slate-400 hover:text-cyan-700"
                        nextLinkClassName="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full bg-cyan-600 text-slate-100 hover:bg-slate-400 hover:text-cyan-700"
                        breakLinkClassName="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full text-cyan-700 hover:bg-slate-400"
                        activeLinkClassName="bg-cyan-800 text-white w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full"
                        marginPagesDisplayed={1}
                    />
                </div>
            </div>
            {
                isTopicAccessModalOpen && (
                    <div className="modal z-50 cursor-pointer" onClick={closeTopicAccessModal}>
                        <div className="modal-content cursor-default" onClick={(e) => e.stopPropagation()}>
                            <span className="close cursor-pointer" onClick={closeTopicAccessModal}><CloseButton></CloseButton></span>
                            <TopicAccessModal topic={selectedTopic} onClose={closeTopicAccessModal}></TopicAccessModal>
                        </div>
                    </div>
                )
            }
            {
                isAskQuestionModalOpen && (
                    <div 
                        className={`modal z-50 cursor-pointer ${isAskQuestionModalOpen ? 'open' : ''}`} 
                        onMouseDown={(e) => {
                            if (e.target === e.currentTarget) {
                                closeAskQuestionModal();
                            }
                        }}
                    >
                        <div className="modal-content cursor-default">
                            <span className="close cursor-pointer" onClick={closeAskQuestionModal}><CloseButton /></span>
                            <CreateTopicModal />
                        </div>
                    </div>
                )
            }
        </div>
    );
};
export default HomePage;
