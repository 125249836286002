import { React, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip'
import ResponseCard from '../components/responseCard';
import TopicCard from '../components/topicCard';
import TopicAccessModal from '../components/topicAccessModal';
import { CloseButton } from '../components/closeButton';
import { FiRefreshCw, FiList, FiCheck, FiInfo, FiBook, FiFileText, FiZap, FiExternalLink, FiChevronDown } from "react-icons/fi";
import MarkdownTextBox from '../components/textBox';

import ProfilePicture from '../components/ProfilePicture';
import { useRemark } from 'react-remark';

const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");
const name = localStorage.getItem("name");
const authLevelJson = localStorage.getItem('auth_level')
const expertAuth = process.env.REACT_APP_LEVEL_EXPERT
const userAuth = process.env.REACT_APP_LEVEL_USER
const isAdmin = localStorage.getItem("is_admin") === "true";


const TopicPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const selectedTopicID = id;
    const { communityConfig } = useAppContext();
    const [responses, setResponses] = useState('loading');
    const [authd, setAuthd] = useState('loading');
    const [isTopicAccessModalOpen, setTopicAccessModalOpen] = useState(false);
    const [responseBody, setResponseBody] = useState('');
    const [reactContent, setMarkdownSource] = useRemark();

    const [topic, setTopic] = useState(null);
    const [flaredAlready, setFlaredAlready] = useState(false);
    const [authLevel, setAuthLevel] = useState(userAuth)
    const [responseCost, setResponseCost] = useState(0);
    const [slashCost, setSlashCost] = useState(0);
    const [endorseCost, setEndorseCost] = useState(0);
    const [expertAuthRequired, setExpertAuthRequired] = useState(false);
    const [showImageInput, setShowImageInput] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [similarTopicSelected, setSimilarTopicSelected] = useState([]);
    const [sortMethod, setSortMethod] = useState('flared');
    const [hasResponded, setHasResponded] = useState(false);
    const [tip, setTip] = useState(0)

    const [isRefreshing, setIsRefreshing] = useState({});
    const [refreshSuccess, setRefreshSuccess] = useState({});
    const [guidelinesExpanded, setGuidelinesExpanded] = useState(false);
    const [researchExpanded, setResearchExpanded] = useState(false);
    const [isDiveBuddyExpanded, setIsDiveBuddyExpanded] = useState(true);

    const [expandedDiagnoses, setExpandedDiagnoses] = useState({});

    const [similarTopics, setSimilarTopics] = useState([]);
    const openTopicAccessModal = (topic) => {
        setTopicAccessModalOpen(true);
    };
    const closeTopicAccessModal = () => {
        setTopicAccessModalOpen(false);
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [selectedTopicID]);
    // Calculate bounty payout
    useEffect(() => {
        if (responses && Array.isArray(responses) && responses.length !== 0) {
            if (responses.some(response => response.owned)) {
                setHasResponded(true);
                setTip(0);
            }
        }
    }, [responses, topic]);

    const calculateTrendingScore = (response) => {
        const ageInDays = (Date.now() - new Date(response.created_at).getTime()) / (1000 * 3600 * 24);
        const recencyScore = 1 / (ageInDays + 1);
        const endorseScore = response.endorsement_count;
        const flareScore = response.flare_count;
        const slashScore = response.slash_count;
        return (recencyScore * 0.1) + (endorseScore * 0.5) + (flareScore * 0.5) + (slashScore * -0.1);
    };

    const sortedResponses = useMemo(() => {
        if (responses === "loading" || !responses) {
            return responses;
        }

        return [...responses].sort((a, b) => {
            switch (sortMethod) {
                case 'recent':
                    return b.created_at.localeCompare(a.created_at);
                case 'endorsed':
                    return b.endorsement_count - a.endorsement_count;
                case 'trending':
                    return calculateTrendingScore(b) - calculateTrendingScore(a);
                case 'flared':
                    return b.flare_count - a.flare_count;
                default:
                    return 0;
            }
        });
    }, [responses, sortMethod]);

    const handleSortChange = (event) => {
        setSortMethod(event.target.value);
    };


    const fetchResponses = useCallback(async () => {
        try {
            const response = await fetch(apiRoot + '/responses?topic_id=' + selectedTopicID.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
            });

            if (response.ok) {
                const data = await response.json();
                // Handle case where data is null/undefined by defaulting to empty array 
                let responseData = data || [];

                // Recursively filter out deleted comments with no children
                const filterDeletedComments = (comments) => {
                    if (!comments) return null;
                    return comments.reduce((filtered, comment) => {
                        // Recursively filter children first
                        if (comment.children) {
                            comment.children = filterDeletedComments(comment.children);
                        }
                        
                        // Only keep comment if it's not deleted or has children
                        if (comment.body !== "Deleted" || (comment.children && comment.children.length > 0)) {
                            filtered.push(comment);
                        }
                        return filtered;
                    }, []);
                };

                // Apply the filter to each response's comments
                responseData = responseData.map(response => ({
                    ...response,
                    comments: filterDeletedComments(response.comments)
                }));

                setFlaredAlready(responseData.some(item => item.has_flared));
                setAuthd(true);
                setResponses(responseData);
            } else {
                if (response.status === 401) {
                    setAuthd(false);
                } else {
                    const responseBody = await response.text();
                    throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
                }
            }
        } catch (error) {
            toast.error(`Error fetching responses: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [selectedTopicID]);


    const updateResponses = useCallback(() => {
        fetchResponses();
    }, [fetchResponses]);

    /* Fetch topic */
    useEffect(() => {
        fetchTopic();
    }, [selectedTopicID]);


    const fetchTopic = async () => {
        var headerAuth = {}
        var owned = ""
        if (jwt) {
            headerAuth = {
                'Authorization': 'Bearer ' + jwt
            }
            owned = "/owned"
        }

        try {
            const response = await fetch(apiRoot + '/topic' + owned + '?topic_id=' + selectedTopicID.toString(), {
                headers: headerAuth,
            });

            // Check if the response is ok and the content type is JSON
            if (response.ok) {
                const data = await response.json();
                setTopic(data);
                setTip(data.per_expert_payout)
                if (jwt) {
                    setAuthLevel(JSON.parse(authLevelJson)[data.subdao_id])
                }
            } else {
                if (response.status === 401) {
                    setAuthd(false)
                } else {
                    const responseBody = await response.text();
                    throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);

                }
            }
        } catch (error) {
            toast.error(`Error fetching responses: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    useEffect(() => {
        if (topic && communityConfig && communityConfig[topic.subdao_id]) {
            setResponseCost(communityConfig[topic.subdao_id].response_cost);
            setSlashCost(communityConfig[topic.subdao_id].slash_cost);
            setEndorseCost(communityConfig[topic.subdao_id].endorse_cost);
            setExpertAuthRequired(communityConfig[topic.subdao_id].expert_auth_required)
        } else {
            setResponseCost(0);
        }
    }, [topic, communityConfig])

    /* Load relevant responses */
    useEffect(() => {
        fetchResponses();
    }, [fetchResponses]);

    useEffect(() => {
        let intervalId;
        if (topic && topic.subdao_id === 3 && !topic.ai_summary) {
            intervalId = setInterval(() => {
                fetchTopic();
            }, 5000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [topic]);
    /* Submit a response */
    const handleResponseSubmit = async (responseText) => {
        if (!topic) return;
        const netCreds = Math.floor(tip - responseCost);
        const netCredsAbs = Math.abs(netCreds);
        let confirmMessage = `Are you sure you want to respond? You will pay ${responseCost} creds.`;
        if (tip > 0) {
            const netCredsDescription = netCreds >= 0 ? `receive ${netCredsAbs} net creds` : `pay ${netCredsAbs} net creds`;
            confirmMessage = `Are you sure you want to respond for ${responseCost} creds? You will receive a ${tip} cred tip and ${netCredsDescription}.`;
        }

        const confirmResponse = window.confirm(confirmMessage);
        if (confirmResponse) {
            try {
                const jsonResponse = {
                    body: responseText
                }
                const response = await fetch(apiRoot + '/response?topic_id=' + selectedTopicID, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify(jsonResponse),
                });
                // Check if the response status is not OK (200-299)
                if (!response.ok) {
                    const responseBody = await response.text();
                    throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
                }
                setSortMethod('recent');
                updateResponses();
                setResponseBody('');
            } catch (error) {
                toast.error(`Error submitting response: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const [refreshKey, setRefreshKey] = useState(0);

    const MemoizedTopicCard = useMemo(() => topic ? (
        <TopicCard
            topic={topic}
            refresh={fetchTopic}
            expanded={true}
        />
    ) : null, [topic, fetchTopic]);

    useEffect(() => {
        const fetchSimilarTopics = async () => {
            try {
                const response = await fetch(`${apiRoot}/topics/owned/similar?topic_id=${selectedTopicID}`, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setSimilarTopics(data);
                } else {
                    throw new Error(`HTTP error: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching similar topics:', error);
            }
        };

        if (selectedTopicID) {
            fetchSimilarTopics();
        }
    }, [selectedTopicID]);
    const refreshAISummary = async (type) => {
        setIsRefreshing(prevState => ({ ...prevState, [type]: true }));
        setRefreshSuccess(prevState => ({ ...prevState, [type]: false }));
        try {
            let url = `${process.env.REACT_APP_API_URL}/topic/ai_refresh?topic_id=${topic.id}`;
            if (type) {
                url += `&type=${type}`;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt
                },
            });
            if (response.ok) {
                const newSummary = await response.json();
                setTopic(prevTopic => ({
                    ...prevTopic,
                    ai_summary: {
                        ...prevTopic.ai_summary,
                        [type]: newSummary[type]
                    }
                }));
                setRefreshSuccess(prevState => ({ ...prevState, [type]: true }));
            } else {
                throw new Error('Failed to refresh AI summary');
            }
        } catch (error) {
            console.error('Error refreshing AI summary:', error);
            toast.error('Failed to refresh AI summary');
        } finally {
            setIsRefreshing(prevState => ({ ...prevState, [type]: false }));
        }
    };


    const renderAISummary = (data) => {
        if (!data) return null;

        const { guidelines, research_summary, differential_diagnosis } = data;

        const renderSources = (sources) => (
            <div className="grid grid-cols-1 mt-2 md:grid-cols-2 xl:grid-cols-3 w-full">
                {sources.map((source, index) => (
                    <div key={index} className="px-3 py-2 rounded-md col-span-1 md:col-span-2 xl:col-span-3">
                        <div className="flex flex-col text-sm">
                            {source.class_of_recommendation && (
                                <p className="text-base">
                                    <span className="text-slate-700">Recommendation:</span>{' '}
                                    <span className="text-cyan-700 font-semibold">{source.class_of_recommendation}</span>
                                </p>
                            )}
                            {source.level_of_evidence && (
                                <p className="text-base ">
                                    <span className="text-slate-700">Evidence Level:</span>{' '}
                                    <span className="text-cyan-700 font-semibold">{source.level_of_evidence}</span>
                                </p>
                            )}
                            <div className="text-gray-600">
                                <h6 className="font-medium text-cyan-900">{source.source_document_title || source.authors}</h6>
                                {(source.journal || source.year_of_publication || source.additional_information?.year_of_publication) && (
                                    <p className="italic font-light">
                                        {source.journal && source.journal.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                        {(source.journal && (source.year_of_publication || source.additional_information?.year_of_publication)) && ", "}
                                        {source.year_of_publication || source.additional_information?.year_of_publication}
                                    </p>
                                )}
                                {(source["PubMed link"] || source["PubMed_link"]) && (
                                    <a href={source["PubMed link"] || source["PubMed_link"]} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline flex items-center">
                                        <FiExternalLink className="h-4 w-4 mr-1" />
                                        PubMed Link
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );


        return (
            <div className="rounded-lg p-4 mt-4 relative">
                <h3
                    className="text-xl font-bold mb-4 text-cyan-800 border-b-[3px] border-cyan-200 select-none cursor-pointer flex justify-between items-center"
                    onClick={() => setIsDiveBuddyExpanded(!isDiveBuddyExpanded)}
                >
                    DiveBuddy
                    <div className="flex items-center">
                        <FiChevronDown className={`h-5 w-5 transition-transform ${isDiveBuddyExpanded ? 'transform rotate-180' : ''}`} />
                    </div>
                </h3>

                {isDiveBuddyExpanded && (
                    <>
                        <div className="flex flex-wrap justify-start gap-4 sm:gap-6 mx-auto">
                            <span className="text-xs text-gray-500 mr-2">DiveBuddy is an experimental AI feature and can provide erroneous information. It should not be used as a substitute for clinical judgment or medical advice. </span>
                            {differential_diagnosis && differential_diagnosis.differential_diagnoses && (
                                <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full bg-white rounded-lg shadow-md h-auto">
                                    <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center justify-between">
                                        <div className="flex select-none items-center">
                                            <FiList className="h-6 w-6 mr-2 text-slate-100" />
                                            Differential Diagnosis
                                        </div>
                                        {isAdmin && (
                                            <button
                                                onClick={() => refreshAISummary('differential_diagnosis')}
                                                className={`text-white transition-colors duration-200 ${isRefreshing.differential_diagnosis ? 'cursor-not-allowed opacity-50' : 'hover:text-cyan-200'}`}
                                                disabled={isRefreshing.differential_diagnosis}
                                            >
                                                {isRefreshing.differential_diagnosis ? (
                                                    <FiRefreshCw className="h-5 w-5 animate-spin" />
                                                ) : refreshSuccess.differential_diagnosis ? (
                                                    <FiCheck className="h-5 w-5" />
                                                ) : (
                                                    <FiRefreshCw className="h-5 w-5" />
                                                )}
                                            </button>
                                        )}
                                    </h4>
                                    <div className="p-4 flex flex-col md:flex-row">
                                        <div className="md:w-1/2 md:pr-4 mb-4 md:mb-0">
                                            <p className="text-gray-700">{differential_diagnosis.aggregated_statement}</p>
                                        </div>
                                        <div className="md:w-1/2 grid grid-cols-1 gap-4">
                                            {differential_diagnosis.differential_diagnoses.map((diagnosis, index) => {
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className={`rounded-md p-3 ${index === 0 ? 'bg-cyan-700' : 'bg-slate-100'} shadow-sm transition-all duration-300 flex items-center cursor-pointer`}
                                                        onClick={() => {
                                                            const newExpandedDiagnoses = { ...expandedDiagnoses };
                                                            newExpandedDiagnoses[index] = !newExpandedDiagnoses[index];
                                                            setExpandedDiagnoses(newExpandedDiagnoses);
                                                        }}
                                                    >
                                                        <div className="flex-grow">
                                                            <div className="flex items-center">
                                                                <h5 className={`text-lg ${index === 0 ? 'text-slate-100' : 'text-cyan-900'} font-semibold select-none flex justify-between items-center flex-grow`}>
                                                                    <span className="flex-grow break-words">{diagnosis.diagnosis}</span>
                                                                    <FiChevronDown 
                                                                        className={`h-6 w-6 ml-2 flex-shrink-0 transition-transform ${expandedDiagnoses[index] ? 'transform rotate-180' : ''}`} 
                                                                    />
                                                                </h5>
                                                            </div>
                                                            <div 
                                                                className={`overflow-hidden transition-all duration-300 ${expandedDiagnoses[index] ? 'max-h-[1000px] mt-4 opacity-100' : 'h-0 opacity-0'}`}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <div className={`cursor-default ${index === 0 ? 'text-slate-100' : 'text-gray-700'}`}>
                                                                    <p className="mb-2"><strong>History Queries:</strong> {diagnosis.suggested_history_queries}</p>
                                                                    <p className="mb-2"><strong>Lab Testing:</strong> {diagnosis.suggested_laboratory_testing}</p>
                                                                    <p className="mb-2"><strong>Imaging Studies:</strong> {diagnosis.suggested_imaging_studies}</p>
                                                                    <p className="mb-2"><strong>Biopsy Required:</strong> {diagnosis.biopsy_required ? 'Yes' : 'No'}</p>
                                                                    {diagnosis.biopsy_required && (
                                                                        <p className="mb-2"><strong>Biopsy Type:</strong> {diagnosis.biopsy_type}</p>
                                                                    )}
                                                                    {diagnosis.specialty_consultants && diagnosis.specialty_consultants.length > 0 && (
                                                                        <p className="mb-2">
                                                                            <strong>Specialty Consultants:</strong> {diagnosis.specialty_consultants.join(', ')}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {guidelines && guidelines.guidelines && Object.keys(guidelines.guidelines).length > 0 && (
                                <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full sm:basis-[calc(50%-1rem)] bg-white rounded-lg shadow-md h-auto">
                                    <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center justify-between">
                                        <div className="flex select-none items-center">
                                            <FiInfo className="h-6 w-6 mr-2 text-slate-100" />
                                            Guidelines
                                        </div>
                                        {isAdmin && (
                                            <button
                                                onClick={() => refreshAISummary('guidelines')}
                                                className={`text-white transition-colors duration-200 ${isRefreshing.guidelines ? 'opacity-50 cursor-not-allowed' : 'hover:text-cyan-200'}`}
                                                disabled={isRefreshing.guidelines}
                                            >
                                                {isRefreshing.guidelines ? (
                                                    <FiRefreshCw className="h-5 w-5 animate-spin" />
                                                ) : refreshSuccess.guidelines ? (
                                                    <FiCheck className="h-5 w-5" />
                                                ) : (
                                                    <FiRefreshCw className="h-5 w-5" />
                                                )}
                                            </button>
                                        )}
                                    </h4>
                                    <div className="p-4 space-y-8">
                                        {Object.entries(guidelines.guidelines).map(([specialty, guidelineData]) => (
                                            <div key={specialty}>
                                                <h5 className="text-lg font-semibold text-cyan-800 mb-3">{specialty}</h5>
                                                {guidelineData.statements && guidelineData.statements.map((statement, index) => (
                                                    <div key={index} className="rounded-md max-w-xl mb-4">
                                                        <p className="text-gray-700 mb-3">{statement.aggregated_statement}</p>
                                                        {statement.sources && statement.sources.length > 0 && (
                                                            <div className="text-sm text-cyan-600">
                                                                <button
                                                                    onClick={() => {
                                                                        const newGuidelinesExpanded = { ...guidelinesExpanded };
                                                                        newGuidelinesExpanded[`${specialty}-${index}`] = !newGuidelinesExpanded[`${specialty}-${index}`];
                                                                        setGuidelinesExpanded(newGuidelinesExpanded);
                                                                    }}
                                                                    className="font-semibold text-cyan-800 flex items-center w-full justify-between"
                                                                >
                                                                    <span className="flex items-center">
                                                                        <FiBook className="h-5 w-5 mr-2 text-cyan-600" />
                                                                        Sources
                                                                    </span>
                                                                    <FiChevronDown className={`h-5 w-5 transition-transform ${guidelinesExpanded[`${specialty}-${index}`] ? 'transform rotate-180' : ''}`} />
                                                                </button>
                                                                {guidelinesExpanded[`${specialty}-${index}`] && renderSources(statement.sources)}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {research_summary && (research_summary.summary || (research_summary.summary && research_summary.summary.statement)) ? (
                                <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full sm:basis-[calc(50%-1rem)] bg-white rounded-lg shadow-md h-auto">
                                    <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center justify-between">
                                        <div className="flex select-none items-center">
                                            <FiFileText className="h-6 w-6 mr-2 text-slate-100" />
                                            Research Summary
                                        </div>
                                        {isAdmin && (
                                            <button
                                                onClick={() => refreshAISummary('research_summary')}
                                                className={`text-white transition-colors duration-200 ${isRefreshing.research_summary ? 'opacity-50 cursor-not-allowed' : 'hover:text-cyan-200'}`}
                                                disabled={isRefreshing.research_summary}
                                            >
                                                {isRefreshing.research_summary ? (
                                                    <FiRefreshCw className="h-5 w-5 animate-spin" />
                                                ) : refreshSuccess.research_summary ? (
                                                    <FiCheck className="h-5 w-5" />
                                                ) : (
                                                    <FiRefreshCw className="h-5 w-5" />
                                                )}
                                            </button>
                                        )}
                                    </h4>
                                    <div className="p-4 space-y-4">
                                        {Array.isArray(research_summary.summary.statements) ? (
                                            research_summary.summary.statements.map((item, index) => (
                                                <div key={index} className="rounded-md max-w-xl">
                                                    <p className="text-gray-700 mb-3">{item.statement}</p>
                                                    {item.citations && item.citations.length > 0 && (
                                                        <div className="text-sm text-cyan-600">
                                                            <button
                                                                onClick={() => {
                                                                    const newResearchExpanded = { ...researchExpanded };
                                                                    newResearchExpanded[index] = !newResearchExpanded[index];
                                                                    setResearchExpanded(newResearchExpanded);
                                                                }}
                                                                className="font-semibold text-cyan-800 flex items-center w-full justify-between"
                                                            >
                                                                <span className="flex items-center">
                                                                    <FiBook className="h-5 w-5 mr-2 text-cyan-600" />
                                                                    Sources
                                                                </span>
                                                                <FiChevronDown className={`h-5 w-5 transition-transform ${researchExpanded[index] ? 'transform rotate-180' : ''}`} />
                                                            </button>
                                                            {researchExpanded[index] && renderSources(item.citations)}
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="rounded-md max-w-xl">
                                                <p className="text-gray-700 mb-3">{research_summary.summary.statement}</p>
                                                {research_summary.summary.citations && research_summary.summary.citations.length > 0 && (
                                                    <div className="text-sm text-cyan-600">
                                                        <button
                                                            onClick={() => setResearchExpanded(!researchExpanded)}
                                                            className="font-semibold text-cyan-800 flex items-center w-full justify-between"
                                                        >
                                                            <span className="flex items-center">
                                                                <FiBook className="h-5 w-5 mr-2 text-cyan-600" />
                                                                Sources
                                                            </span>
                                                            <FiChevronDown className={`h-5 w-5 transition-transform ${researchExpanded ? 'transform rotate-180' : ''}`} />
                                                        </button>
                                                        {researchExpanded && renderSources(research_summary.summary.citations)}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full sm:basis-[calc(50%-1rem)] bg-white rounded-lg shadow-md h-auto">
                                    <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center justify-between">
                                        <div className="flex items-center">
                                            <FiFileText className="h-6 w-6 mr-2 text-slate-100" />
                                            Research Summary
                                        </div>
                                        {isAdmin && (
                                            <button
                                                onClick={() => refreshAISummary('research_summary')}
                                                disabled={isRefreshing.research_summary}
                                                className={`text-white transition-colors duration-200 ${
                                                    isRefreshing.research_summary ? 'opacity-50 cursor-not-allowed' : 'hover:text-cyan-200'
                                                }`}
                                            >
                                                {isRefreshing.research_summary ? (
                                                    <FiRefreshCw className="h-5 w-5 animate-spin" />
                                                ) : refreshSuccess.research_summary ? (
                                                    <FiCheck className="h-5 w-5" />
                                                ) : (
                                                    <FiRefreshCw className="h-5 w-5" />
                                                )}
                                            </button>
                                        )}
                                    </h4>
                                    <div className="p-4">
                                        <p className="text-gray-700">No relevant PubMed research found for this topic</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
    if (authd === true) {
        return (
            <div className="flex relative bg-slate-100 flex-col items-center w-full">
                <div className="items-stretch self-stretch bg-slate-50 flex flex-col justify-center px-5 max-md:max-w-full">
                    {MemoizedTopicCard}
                </div>
                {(authLevel == expertAuth) && <div className="flex w-full min-h-64 bg-slate-100 overflow-hidden">
                    <div className='flex flex-col items-end gap-6 w-full p-6'>
                        <div className="flex flex-col gap-4 w-full">
                            <MarkdownTextBox
                                initialText={responseBody}
                                onSubmit={(output) => handleResponseSubmit(output)}
                                placeholder="Type your response here"
                                toolbarItems={['bold', 'italic', 'unordered-list', 'ordered-list', 'link', 'quote', 'image']}
                                submitButtonText={`Respond (${tip - responseCost >= 0 ? 'receive' : 'spend'} ${Math.abs(Math.floor(tip - responseCost))} creds)`}
                            />
                            {topic && (
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <div className="rounded-full object-contain w-8 h-8 overflow-hidden shrink-0 max-w-full">
                                            <ProfilePicture pseudoanon={false} url={localStorage.getItem("profile_picture_url")} />
                                        </div>
                                        <div className="text-zinc-600 text-sm font-medium leading-5"> {name} </div>
                                    </div>
                                    <div 
                                        data-tooltip-id="respond-tooltip"
                                        data-tooltip-html={
                                            `<span className="text-green-200"> + ${Math.floor(tip)} creds (tip) <br/> </span>
                                            - ${responseCost} creds (response cost) <br/>
                                            = ${Math.abs(Math.floor(tip - responseCost))} creds ${tip - responseCost >= 0 ? 'received' : 'spent'}`}
                                        data-tooltip-place="left"
                                        className="text-xs text-gray-500"
                                    >
                                        {tip - responseCost >= 0 ? 'receive' : 'spend'} {Math.abs(Math.floor(tip - responseCost))} creds
                                    </div>
                                    <Tooltip id="respond-tooltip" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>}
                <div className="flex bg-slate-200 w-full justify-end select-none flex-grow shadow-sm">
                    <select
                        className="cursor-pointer outline-none text-right text-slate-600 py-3 mr-3 text-center bg-transparent"
                        value={sortMethod}
                        onChange={handleSortChange}
                    >
                        <option value="trending">Trending</option>
                        <option value="recent">Recent</option>
                        <option value="endorsed">Endorsements</option>
                        <option value="flared">Flares</option>
                    </select>
                </div>
                <div className="items-stretch self-stretch bg-slate-100 rounded-b-lg flex flex-col justify-center overflow-hidden shadow-lg">
                    {!topic ? (
                        <TopicCard topic={null} />
                    ) : responses === "loading" ? (
                        <div className="items-stretch border-b-2 border-solid py-5">
                            <ResponseCard response={null} />
                        </div>
                    ) : responses === null || responses.length === 0 ? (
                        <div className='py-10 select-none text-cyan-950 text-center text-xl font-semibold leading-8 my-3 max-md:max-w-full'>No responses yet!</div>
                    ) : (
                        sortedResponses.map((response) => (
                            response && (
                                <ResponseCard
                                    key={"response-" + response.id}
                                    expert_required={expertAuthRequired}
                                    slash_cost={slashCost}
                                    authLevel={authLevel}
                                    response={response}
                                    endorse_cost={endorseCost}
                                    flared_already={flaredAlready}
                                    topic_creator_address_id={topic.creator_address_id}
                                />
                            )
                        ))
                    )}
                </div>
                {topic && (
                    topic.ai_summary ? (
                        renderAISummary(topic.ai_summary)
                    ) : topic.subdao_id === 3 ? (
                        <div className="flex flex-col items-center justify-center p-8">
                            <FiRefreshCw className="w-8 h-8 animate-spin text-cyan-600 mb-2" />
                            <p className="text-cyan-800">Generating DiveBuddy summary</p>
                        </div>
                    ) : null
                )}
                {(isAdmin && topic && topic.subdao_id === 3) && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            refreshAISummary("all");
                        }}
                        disabled={isRefreshing.all}
                        className="py-2 m-auto px-3 rounded-full shadow bg-cyan-800 hover:bg-cyan-900 transition-colors duration-200 my-4 flex items-center justify-center text-white"
                    >
                        <span className="mr-2">Refresh or create AI summary</span>
                        {isRefreshing.all ? (
                            <FiRefreshCw className="w-5 h-5 animate-spin text-white" />
                        ) : refreshSuccess.all ? (
                            <FiCheck className="w-5 h-5 text-white" />
                        ) : (
                            <FiRefreshCw className="w-5 h-5 text-white" />
                        )}
                    </button>
                )}
                {similarTopics && similarTopics.length > 0 && (
                    <div className="select-none w-full rounded-t-lg inner-shadow-lg p-6 bg-cyan-900 relative overflow-hidden">
                        <div className="absolute inset-0 bg-cyan-950 opacity-30 animate-pulse"></div>
                        <h2 className="text-2xl font-bold mb-4 text-cyan-100 pb-2 text-center relative z-10">Dive deeper</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative z-10">
                            {similarTopics.map((similarTopic) => (
                                <div
                                    key={similarTopic.id}
                                    className="rounded-lg bg-slate-100 px-4 py-0.5 shadow cursor-pointer hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
                                    onClick={() => {
                                        if (similarTopic.authd) {
                                            navigate(`/topic/${similarTopic.id}`);
                                        } else {
                                            setSimilarTopicSelected(similarTopic);
                                            openTopicAccessModal();
                                        }
                                    }}
                                >
                                    <TopicCard topic={similarTopic} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {isTopicAccessModalOpen && similarTopics && similarTopics.length > 0 && (
                    <div className="modal z-50 cursor-pointer" onClick={closeTopicAccessModal}>
                        <div className="modal-content cursor-default" onClick={(e) => e.stopPropagation()}>
                            <span className="close cursor-pointer" onClick={closeTopicAccessModal}><CloseButton /></span>
                            <TopicAccessModal topic={similarTopicSelected} onClose={closeTopicAccessModal} />
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div className="bg-white pt-4 px-2 relative flex grow flex-col items-center w-full">
                {authd === false && (
                    <TopicAccessModal topic={topic} onClose={fetchTopic} />
                )}
            </div>
        );
    }
};

export default TopicPage;
