import { React } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AppProvider } from './AppContext';
import { isJwtExpired } from './components/login'; // Adjust the import path as necessary
import TopicPage from './pages/Topic.jsx'; // Import your topic page component
import HomePage from './pages/Home.jsx'; // Import your home page component
import HistoryPage from './pages/History.jsx'; // Import your home page component
import ValidatePage from './pages/Validate.jsx';
import ProfilePage from './pages/Profile.jsx';
import Layout from './components/layout';
import Databoard from './pages/Databoard.jsx';
import DiveBuddy from './pages/Divebuddy.jsx';
// Import other components or pages if necessary

const apiRoot = process.env.REACT_APP_API_URL;

function App() {
  const jwt = localStorage.getItem("token");
  const emailValidated = localStorage.getItem('email_validated') === 'true';
  if (jwt && isJwtExpired(jwt)) {
    localStorage.removeItem("token");
  }

  const renderPage = (PageComponent) => jwt && !emailValidated ? <ValidatePage /> : <PageComponent />;

  return (
    <Router>
      <AppProvider>
        <Layout>
          <Routes>
            <Route path="/" element={renderPage(HomePage)} />
            <Route path="/topic/:id" element={renderPage(TopicPage)} />
            <Route path="/history" element={renderPage(HistoryPage)} />
            <Route path="/validate" element=<ValidatePage /> />
            <Route path="/profile" element={renderPage(ProfilePage)} />
            <Route path="/databoard" element={renderPage(Databoard)} />
            <Route path="/divebuddy" element={renderPage(DiveBuddy)} />
            {/* Define other routes as needed */}
          </Routes>
        </Layout>
      </AppProvider>
    </Router>
  );
}
export default App;
